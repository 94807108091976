import React from 'react'
import { GoDotFill } from 'react-icons/go'

export default function News() {
    return (
        <>
            <div className={`custom_container`}>
                {/* <h2 className='uppercase text-6xl md:text-[90px] md:leading-[100px] font-extrabold'>read some <span style={{
                    WebkitTextStroke: '1px black',
                    color: 'transparent',
                }}>news</span></h2> */}
                <h2 className='text-3xl sm:text-[54px] mb-14 font-extrabold text-center'>Here Come The News
                </h2>

                <div className={`grid md:grid-cols-2 lg:grid-cols-3 gap-5 mt-4`}>
                    <div className={`col-span-1 border border-black rounded-[50px] overflow-hidden space-y-4`}>
                        <div className={`relative h-[300px] border-b border-black  rounded-[50px] overflow-hidden`}>
                            <img src="/image/home/blog1.jpg" alt="blog1" className='h-full object-cover w-full' />
                            <div className={`border border-black rounded-xl bg-[#f8a065] absolute top-6 right-6 p-2 w-min text-center`}>
                                <h4 className='text-2xl font-semibold'> 13 MAR</h4>
                            </div>
                        </div>
                        <div className={`space-y-2 p-4 lg:p-8`}>
                            <p className='uppercase text-sm'>(creative)</p>
                            <h2 className='capitalize text-xl lg:text-2xl font-semibold'>Got Your Eye On This Season’s Trends</h2>
                            <p className='flex uppercase text-sm items-center gap-2'>
                                <GoDotFill className='text-[#f8a065]' />  Read More
                            </p>
                        </div>
                    </div>
                    <div className={`col-span-1 border border-black rounded-[50px] overflow-hidden space-y-4`}>
                        <div className={`relative h-[300px] border-b border-black  rounded-[50px] overflow-hidden`}>
                            <img src="/image/home/blog2.jpg" alt="blog1" className='h-full object-cover w-full' />
                            <div className={`border border-black rounded-xl bg-[#f8a065] absolute top-6 right-6 p-2 w-min text-center`}>
                                <h4 className='text-2xl font-semibold'> 13 MAR</h4>
                            </div>
                        </div>
                        <div className={`space-y-2 p-4 lg:p-8`}>
                            <p className='uppercase text-sm'>(creative)</p>
                            <h2 className='capitalize text-xl lg:text-2xl font-semibold'>Got Your Eye On This Season’s Trends</h2>
                            <p className='flex uppercase text-sm items-center gap-2'>
                                <GoDotFill className='text-[#f8a065]' />  Read More
                            </p>
                        </div>
                    </div>
                    <div className={`col-span-1 border border-black rounded-[50px] overflow-hidden space-y-4`}>
                        <div className={`relative h-[300px] border-b border-black  rounded-[50px] overflow-hidden`}>
                            <img src="/image/home/blog3.jpg" alt="blog1" className='h-full object-cover w-full' />
                            <div className={`border border-black rounded-xl bg-[#f8a065] absolute top-6 right-6 p-2 w-min text-center`}>
                                <h4 className='text-2xl font-semibold'> 13 MAR</h4>
                            </div>
                        </div>
                        <div className={`space-y-2 p-4 lg:p-8`}>
                            <p className='uppercase text-sm'>(creative)</p>
                            <h2 className='capitalize text-xl lg:text-2xl font-semibold'>Got Your Eye On This Season’s Trends</h2>
                            <p className='flex uppercase text-sm items-center gap-2'>
                                <GoDotFill className='text-[#f8a065]' />  Read More
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
