import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";

const faqData = [
    // Web Development FAQs
    { question: "What is web development and how can it benefit my business?", answer: "Web development involves building websites or web applications. It helps businesses enhance their online presence, engage customers, and drive growth." },
    { question: "How long does it take to develop a website from scratch?", answer: "The time to develop a website can range from 1 to 4 months, depending on its complexity and features." },
    { question: "What technologies do you use for web development?", answer: "We use a variety of technologies, including HTML, CSS, JavaScript, PHP, React, and Laravel, based on project requirements." },
    { question: "Can I request custom features for my website?", answer: "Yes, we can develop custom features tailored to your specific business needs and goals." },
    { question: "Do you offer e-commerce website development?", answer: "Yes, we offer comprehensive e-commerce website development, including payment gateway integration and inventory management." },

    // Web Design & UI/UX FAQs
    { question: "What’s the difference between web design and UI/UX design?", answer: "Web design focuses on the visual layout, while UI/UX design emphasizes improving user interaction and experience." },
    { question: "How can a good UI/UX design improve my website’s performance?", answer: "A well-designed UI/UX ensures ease of use, increases user engagement, and boosts conversions." },
    { question: "Can you redesign an existing website?", answer: "Yes, we offer website redesign services to improve aesthetics, functionality, and user experience." },
    { question: "How do you ensure that your web designs are mobile-friendly?", answer: "We use responsive design techniques to ensure websites perform well on mobile devices, providing an optimal experience for all users." },
    { question: "What is the process for designing a user-friendly website?", answer: "Our process includes research, wireframing, prototyping, and continuous user testing to ensure ease of use and functionality." },

    // Custom Software Development FAQs
    { question: "What types of custom software development do you offer?", answer: "We offer a range of custom software solutions, including CRM, ERP, inventory management, and more tailored to your business needs." },
    { question: "How does custom software development differ from off-the-shelf solutions?", answer: "Custom software is designed specifically for your business, providing more flexibility, scalability, and efficiency compared to ready-made solutions." },
    { question: "Can you create custom software that integrates with my existing systems?", answer: "Yes, we develop software that seamlessly integrates with your existing systems, ensuring smooth operations and improved efficiency." },
    { question: "What industries do you specialize in for custom software development?", answer: "We specialize in various industries, including retail, healthcare, finance, and logistics." },
    { question: "How scalable are the custom software solutions you provide?", answer: "Our custom software solutions are highly scalable and designed to grow alongside your business." },

    // Custom Web App Development FAQs
    { question: "What’s the difference between a website and a web application?", answer: "A website provides information, while a web application allows users to interact and perform specific tasks, such as online banking or project management." },
    { question: "How long does it take to develop a custom web app?", answer: "Development time depends on the complexity, ranging from 3 to 6 months or more for larger projects." },
    { question: "Can you develop web apps that work across all devices?", answer: "Yes, we build web apps that are responsive and optimized for all devices, including smartphones and tablets." },
    { question: "What types of businesses benefit most from custom web app development?", answer: "Any business requiring specialized online interactions or tools, such as booking systems or customer portals, can benefit from custom web apps." },
    { question: "What security measures do you implement in web app development?", answer: "We implement robust security protocols, including data encryption, secure authentication, and regular updates, to protect your web apps." },

    // Digital Marketing FAQs
    { question: "What digital marketing services do you offer?", answer: "We offer SEO, PPC, social media marketing, influencer marketing, content marketing, and email marketing." },
    { question: "How can digital marketing help grow my business?", answer: "Digital marketing helps increase brand visibility, attract customers, and boost conversions through targeted strategies and campaigns." },
    { question: "What’s the difference between SEO and PPC in digital marketing?", answer: "SEO focuses on organic search engine rankings, while PPC is paid advertising, such as Google Ads, to drive immediate traffic." },
    { question: "How do you measure the success of a digital marketing campaign?", answer: "We use metrics like traffic, engagement, conversion rates, and ROI to measure the success of each campaign." },
    { question: "Can digital marketing increase my website’s traffic?", answer: "Yes, through SEO, PPC, and social media marketing, we can drive more traffic to your website and enhance online visibility." },

    // Social Media Marketing FAQs
    { question: "Which social media platforms should my business focus on?", answer: "It depends on your audience, but popular platforms include Facebook, Instagram, LinkedIn, and Twitter." },
    { question: "How can social media marketing help my brand?", answer: "Social media marketing increases brand awareness, engagement, and customer loyalty by connecting with your audience in a more personal way." },
    { question: "What types of content do you create for social media?", answer: "We create posts, videos, infographics, and stories that engage and inform your audience while promoting your brand." },
    { question: "Can you manage my social media accounts?", answer: "Yes, we offer full social media management services, including content creation, scheduling, and performance tracking." },
    { question: "How do you run a successful social media campaign?", answer: "We run targeted campaigns based on audience insights, creating engaging content and measuring success through detailed analytics." },

    // Influencer Marketing FAQs
    { question: "What is influencer marketing and how does it work?", answer: "Influencer marketing involves partnering with social media influencers to promote your products or services to their followers." },
    { question: "How do you find the right influencers for my brand?", answer: "We identify influencers based on their relevance to your niche, audience demographics, and engagement rates." },
    { question: "What industries benefit the most from influencer marketing?", answer: "Industries like fashion, beauty, fitness, tech, and lifestyle often see the best results from influencer marketing." },
    { question: "Can you track the ROI of an influencer marketing campaign?", answer: "Yes, we track engagement, reach, and conversions to measure the success of each influencer campaign." },
    { question: "How do influencers help boost brand visibility and engagement?", answer: "Influencers provide authentic endorsements, helping you reach a wider audience and build trust with potential customers." },

    // SEO FAQs
    { question: "What is SEO and why is it important?", answer: "SEO (Search Engine Optimization) improves your website’s visibility on search engines, helping drive organic traffic and boost rankings." },
    { question: "How long does it take to see SEO results?", answer: "SEO results typically take 3 to 6 months to show, depending on the competitiveness of your industry and keywords." },
    { question: "What’s the difference between on-page and off-page SEO?", answer: "On-page SEO involves optimizing content on your site, while off-page SEO focuses on external factors like backlinks." },
    { question: "Can you guarantee first-page rankings on Google?", answer: "No one can guarantee first-page rankings, but our SEO strategies significantly improve your chances of achieving high rankings." },
    { question: "How do you perform keyword research for SEO?", answer: "We use advanced tools and competitor analysis to find the best keywords relevant to your business and target audience." },

    // CRM FAQs
    { question: "What is a CRM system, and how can it help my business?", answer: "CRM (Customer Relationship Management) systems manage customer interactions and data, helping improve relationships and streamline sales." },
    { question: "Can you customize a CRM system based on my business needs?", answer: "Yes, we provide customized CRM solutions to meet your specific business requirements and workflows." },
    { question: "How can a CRM system improve customer relationships?", answer: "CRM systems help you stay connected with customers, track interactions, and provide personalized services, leading to better relationships." },
    { question: "What features are included in your CRM solutions?", answer: "Our CRM solutions include customer data management, sales tracking, marketing automation, and analytics." },
    { question: "Do you offer support for CRM integration with existing tools?", answer: "Yes, we offer integration services to connect your CRM with existing systems like email, e-commerce, or accounting platforms." },

    // ERP FAQs
    { question: "What is ERP, and how can it benefit my business?", answer: "ERP (Enterprise Resource Planning) integrates business processes, improving efficiency, decision-making, and scalability across departments." },
    { question: "Can you develop custom ERP solutions for my company?", answer: "Yes, we create custom ERP solutions that are tailored to your company’s specific processes and goals." },
    { question: "What industries benefit most from ERP systems?", answer: "Industries like manufacturing, retail, healthcare, and logistics benefit most from ERP systems by streamlining operations." },
    { question: "How long does it take to implement an ERP system?", answer: "ERP implementation timelines vary but can take 6 to 12 months, depending on the size and complexity of your business." },
    { question: "Can you integrate ERP systems with other business tools?", answer: "Yes, we can integrate ERP systems with other software like accounting, HR, or supply chain management tools for a seamless workflow." },

    // Graphics & Design FAQs
    { question: "What graphic design services do you offer?", answer: "We offer logo design, branding, marketing materials, social media graphics, and website visuals." },
    { question: "How important is branding in graphic design?", answer: "Branding is essential in creating a unique identity for your business, making it stand out and resonate with your audience." },
    { question: "Can you create logos and other branding elements for my business?", answer: "Yes, we specialize in creating logos, color schemes, and other branding elements that align with your brand’s identity." },
    { question: "How do you ensure that the designs align with my brand’s vision?", answer: "We work closely with you, gathering input and feedback throughout the design process to ensure our designs reflect your brand’s vision." },
    { question: "Do you offer graphic design for social media and marketing materials?", answer: "Yes, we create engaging visuals for social media posts, advertisements, banners, brochures, and other marketing materials." },

    // YouTube Marketing FAQs
    { question: "What is YouTube marketing and how can it help my business?", answer: "YouTube marketing uses video content to engage audiences, build brand authority, and drive traffic to your website." },
    { question: "How do you create content for YouTube marketing?", answer: "We create compelling videos, including product demos, tutorials, and behind-the-scenes content, tailored to your brand’s goals." },
    { question: "Can you help optimize my YouTube videos for better visibility?", answer: "Yes, we use SEO techniques like keyword optimization, proper tagging, and engaging thumbnails to improve video visibility." },
    { question: "How do you track the success of YouTube marketing campaigns?", answer: "We track views, engagement, click-through rates, and conversions to measure the effectiveness of YouTube campaigns." },
    { question: "What types of businesses benefit from YouTube marketing?", answer: "Businesses in industries like entertainment, education, e-commerce, and tech benefit from the visual storytelling YouTube marketing offers." },

    // Meta (Facebook/Instagram) Marketing FAQs
    { question: "What is Meta marketing and how does it work?", answer: "Meta marketing involves promoting your business through Facebook and Instagram to reach and engage with your target audience." },
    { question: "How can advertising on Facebook and Instagram help my brand?", answer: "Advertising on these platforms increases brand awareness, drives website traffic, and boosts sales by targeting specific audience segments." },
    { question: "What’s the difference between organic and paid Meta marketing?", answer: "Organic marketing involves engaging with followers naturally, while paid marketing includes targeted ads to reach a broader audience." },
    { question: "How do you run a successful Facebook/Instagram ad campaign?", answer: "We create targeted ads, define specific audience demographics, and use A/B testing to optimize campaign performance." },
    { question: "How do you target the right audience on Meta platforms?", answer: "We use Meta’s advanced targeting options, such as age, location, interests, and behaviors, to reach the right audience for your business." },

    // Google Ads & PPC Advertising FAQs
    { question: "What is Google Ads, and how does it work?", answer: "Google Ads is a pay-per-click advertising platform where businesses bid on keywords to show ads on Google search results and partner sites." },
    { question: "How can PPC advertising help my business grow?", answer: "PPC drives immediate traffic to your website, helping you generate leads and increase sales by targeting specific search terms." },
    { question: "How do you choose the right keywords for Google Ads?", answer: "We perform extensive keyword research, analyzing competition and relevance to your business, to choose the best-performing keywords." },
    { question: "What is the difference between Google Ads and SEO?", answer: "Google Ads is a paid advertising model, while SEO focuses on improving organic rankings and long-term traffic." },
    { question: "How do you measure the ROI of a Google Ads campaign?", answer: "We track clicks, conversions, cost-per-click, and overall revenue generated to assess the ROI of Google Ads campaigns." }
];


const FAQContent = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        // <div className="w-[85%] m-auto">
        <div className="mt-12 mb-32 custom_container">
            {/* <div className="grid lg:grid-cols-12 gap-y-6">
                    <div className="lg:col-span-8"> */}
            {faqData.map((faq, index) => (
                <div key={index} className="border-b border-black">
                    <div
                        className="flex justify-between py-8 cursor-pointer"
                        onClick={() => toggleFAQ(index)}
                    >
                        <h2 className="text-2xl font-semibold">{faq.question}</h2>
                        <div className={`h-[30px] w-[30px] border border-black flex justify-center items-center rounded-full ${openIndex === index ? 'bg-[#f8a065]' : ''}`}>
                            <span className="text-2xl">
                                {openIndex === index ? "−" : "+"}
                            </span>
                        </div>
                    </div>
                    {openIndex === index && (
                        <div className="pb-10 text-gray-600 sm:w-1/2 text-lg">
                            <p>{faq.answer}</p>
                        </div>
                    )}
                </div>
            ))}
            {/* </div> */}
            {/* <div className="hidden lg:block col-span-1"></div>
                    <div className="lg:col-span-3">
                        <div className="space-y-8">
                            <div className={`border w-full border-black rounded-full overflow-hidden relative`}>
                                <input type="text" placeholder='Email...' className='bg-white w-full border-0 outline-0 h-full p-6' />
                                <button className='border border-r-0 border-black rounded-full bg-[#f8a065] p-2 absolute right-0 h-full w-[65px]'>
                                    <FaArrowRightLong className='text-2xl m-auto' />
                                </button>
                            </div>
                            <div className="space-y-3">
                                <h4 className='text-2xl font-semibold !mb-8'>Contact Us</h4>
                                <p>Delsjögatan, 217 Malmö, Sweden</p>
                                <p>randal@example.com</p>
                                <p>+1123456788</p>
                            </div>
                            <div className="h-[250px] w-full rounded-xl overflow-hidden">
                                <img src="/image/portfoilo/faq-image.jpg" alt="" className="w-full object-cover" />
                            </div>
                            <div className="space-y-3">
                                <h4 className='text-2xl font-semibold !mb-8'>Why Are We Here?</h4>
                                <p>Donec enim diam vulputate ut pharetra sit. Magna etiam tempor orci eu lobortis elementum nibh tellus molestie.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* </div> */}
        </div>
    );
};

export default FAQContent;
