import React from 'react'
import FAQContent from '../../component/faq-content/content'
import Banner from '../../component/home/Banner'

export default function Faq() {
    return (
        <>
            <Banner />
            <FAQContent />
        </>
    )
}
