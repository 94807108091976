import './App.css';
import './styles/custom.css';
import Home from './app/home/Home';
import Header from './component/layout/Header';
import Footer from './component/layout/Footer';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from './app/about/About';
import Contact from './app/contact/Contact';
import Portfolio from './app/portfolio/Portfolio';
import Faq from './app/faq/Faq';
import NotFound from './app/not-found/NotFound';
import Post from './app/blog/post/Post';
import Blog from './app/blog/Blog';


function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/faq" element={<Faq />} />
        {/* <Route path="/post/:id" element={<Post />} /> */}
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
