import React from 'react';

const NotFound = () => {
    return (
        <div className='h-[300px] w-full grid items-center justify-center'>
            <div className='text-center'>
                <h1>404 - Page Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
            </div>
        </div>
    );
};

export default NotFound;
