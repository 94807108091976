import React from "react";
import Button from "../../common/Button";
import { GoDotFill } from "react-icons/go";

const ContactForm = () => {

    return (
        <div className="border border-black rounded-[50px] overflow-hidden justify-center">
            <div className="grid lg:grid-cols-2 p-6 bg-[#cbdec6] rounded-lg shadow-md h-full">
                {/* Left Side: Map */}
                <div className="w-full lg:col-span-1">
                    <iframe
                        title="Google Maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2462.755417289713!2d-0.41751102445490484!3d51.883676983147126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876484f78a3b0d3%3A0x3c0ca6a202633047!2s6-8%20Dudley%20St%2C%20Luton%20LU2%200NT!5e0!3m2!1sen!2suk!4v1730473403335!5m2!1sen!2suk"
                        className="w-full h-[500px] lg:h-full border-0 rounded-[30px]"
                        allowFullScreen={true}
                        loading="lazy"
                    ></iframe> 

{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2462.755417289713!2d-0.41751102445490484!3d51.883676983147126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876484f78a3b0d3%3A0x3c0ca6a202633047!2s6-8%20Dudley%20St%2C%20Luton%20LU2%200NT!5e0!3m2!1sen!2suk!4v1730473403335!5m2!1sen!2suk" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </div>

                {/* Right Side: Contact Form */}
                <div className="w-full lg:col-span-1 px-0 md:px-8 lg:px-20 py-16">
                    <h2 className="text-5xl md:text-6xl font-semibold text-[#151015] mb-12">Drop Us A Note!</h2>
                    <form className="space-y-8 mt-10">
                        <div>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name*"
                                className="w-full py-4 px-5 border border-black focus:outline-none rounded-full"
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email*"
                                className="w-full py-4 px-5 border border-black focus:outline-none rounded-full"
                            />
                        </div>
                        <div>
                            <textarea
                                name="message"
                                placeholder="Message*"
                                rows={4}
                                className="w-full py-4 px-5 border border-black focus:outline-none rounded-3xl"
                            ></textarea>
                        </div>
                        <div className="flex">
                            <button
                                type="submit"
                            // className="px-6 py-3 text-black shadow hover:bg-[#f8a065] hover:text-white bg-white rounded-full"
                            >
                                {/* <Button text="Send Message" width="200px" /> */}
                                <button className={`relative border border-black rounded-full font-semibold px-3 group hover:px-0 py-2 duration-100 ease-in-out cursor-pointer uppercase w-[200px] m-auto text-lg overflow-hidden flex items-center mt-10`}>
                                    <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-[#f8a065] rounded-full group-hover:w-full group-hover:h-56"></span>
                                    <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent"></span>
                                    <div className="sliding-text-wrapper flex items-center whitespace-nowrap relative z-10">
                                        <GoDotFill className="text-[#f8a065]" />
                                        <span className="sliding-text hidden group-hover:block">Send Message</span>
                                        <GoDotFill className="text-[#f8a065] hidden group-hover:block" />
                                        <span className="sliding-text hidden group-hover:block">Send Message</span>
                                        <GoDotFill className="text-[#f8a065] hidden group-hover:block" />
                                        <span className="sliding-text">Send Message</span>
                                    </div>
                                </button>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;


































// import React, { useEffect } from "react";

// const loadGoogleMapsScript = (callback: () => void) => {
//     const existingScript = document.getElementById("googleMaps");

//     if (!existingScript) {
//         const script = document.createElement("script");
//         script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap`;
//         script.id = "googleMaps";
//         document.body.appendChild(script);

//         script.onload = () => {
//             if (callback) callback();
//         };
//     } else {
//         if (callback) callback();
//     }
// };

// const ContactForm = () => {
//     useEffect(() => {
//         const initMap = () => {
//             const location = { lat: 28.6202, lng: 77.3397 }; // Current location
//             const map = new google.maps.Map(document.getElementById("map"), {
//                 zoom: 14,
//                 center: location,
//             });

//             const marker = new google.maps.Marker({
//                 position: location,
//                 map: map,
//                 icon: {
//                     url: "/path/to/logo.png", // Replace with the path to your logo
//                     scaledSize: new google.maps.Size(50, 50),
//                 },
//             });
//         };

//         loadGoogleMapsScript(() => {
//             if (window.google) {
//                 initMap();
//             }
//         });
//     }, []);

//     return (
//         <div className="border border-black rounded-[50px] overflow-hidden justify-center">
//             <div className="grid md:grid-cols-2 p-6 bg-[#cbdec6] rounded-lg shadow-md h-full">
//                 {/* Left Side: Map */}
//                 <div className="w-full md:col-span-1">
//                     <div id="map" className="w-full h-[500px] md:h-full rounded-[30px]"></div>
//                 </div>

//                 {/* Right Side: Contact Form */}
//                 {/* ...rest of your form code */}
//             </div>
//         </div>
//     );
// };

// export default ContactForm;
