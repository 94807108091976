import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdFormatQuote } from 'react-icons/md';

export const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='custom_container relative pb-20'>
            <Slider {...settings}>
                <div className={`relative mb-[40px] pr-8`}> {/* Add mx-4 or any other margin class here */}
                    <img src={`/image/home/persion1.jpg`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-1`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-2 sm:p-8 rounded-2xl `}>
                        <h3 className={`font-semibold text-xl`}>Emily R., E-Commerce Business Owner</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}><p>Everything Globle completely transformed our online presence. Their web development team delivered a stunning, fast, and secure website that exceeded our expectations. Their marketing strategies have helped us grow our traffic and revenue significantly.</p></div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl'/>
                    </div>
                </div>
                <div className={`relative mb-[40px] pr-8`}> {/* Add mx-4 or any other margin class here */}
                    <img src={`/image/home/persion2.jpg`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-1`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-2 sm:p-8 rounded-2xl `}>
                        <h3 className={`font-semibold text-xl`}>John D., Founder of Tech Startup</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}><p>From the start, Everything Globle understood our vision and delivered an incredible digital marketing campaign. Their SEO efforts pushed us to the top of search rankings, and the results were immediate. Highly recommend their services!</p></div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl'/>
                    </div>
                </div>
                <div className={`relative mb-[40px] pr-8`}> {/* Add mx-4 or any other margin class here */}
                    <img src={`/image/home/persion3.jpg`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-1`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-2 sm:p-8 rounded-2xl `}>
                        <h3 className={`font-semibold text-xl`}>Sarah M., Marketing Manager, Financial Services</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}><p>Professionalism and creativity at its finest! The team at Everything Globle designed our website with a focus on UI/UX that our customers love. Their attention to detail and marketing expertise make them a reliable long-term partner.</p></div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl'/>
                    </div>
                </div>
            </Slider>
            <div className="swiper-pagination-custom mt-4"></div>
        </div>
    );
};
