import React from 'react'
import ContactForm from '../../component/pages/contact/ContactForm'

export default function Contact() {
    return (
        <>
            <div className={`custom_container sm:!py-10`}>
                <div className={`grid lg:grid-cols-12 gap-10`}>
                    <div className={`col-span-6 content-end pb-8 max-lg:order-1 xl:mr-32`}>
                        <div className='space-y-4 mb-20 lg:pr-10'>
                            <div className='relative'>
                                <h1 className='text-[50px] sm:text-[64px] sm:leading-[70px] font-semibold relative z-10'>Contact Us.<br />
                                    Let’s Work Together!</h1>
                                <span className={`absolute hidden lg:block z-0 right-10 md:right-40 lg:right-10 bottom-36`}>
                                    <img src="/image/home/happy.webp" alt="Error" />
                                </span>
                            </div>
                            <p>Let's connect and bring your ideas to life. Whether you're looking to enhance your digital presence or develop innovative solutions, our team is here to guide you every step of the way. Your journey starts with a conversation—let's make it happen!</p>
                        </div>
                        <div className="grid grid-cols-2 gap-5">
                            <div className="col-span-1">
                                <div className="space-y-3">
                                    <h4 className='text-2xl font-semibold'>UK</h4>
                                    <p>6-8 Dudley Street, Luton, England</p>
                                    <p>info@everythinggloble.com</p>
                                    <p>+44 7880 205836</p>
                                </div>
                            </div>
                            {/* <div className="col-span-1">
                                <div className="space-y-3">
                                    <h4 className='text-2xl font-semibold'>New York</h4>
                                    <p>Delsjögatan, 217 Malmö, Sweden</p>
                                    <p>randal@example.com</p>
                                    <p>+1123456788</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className={`col-span-6 rounded-[50px] overflow-hidden`}>
                        <img src="/image/portfoilo/contact.jpg" alt="Error" className='h-full' />
                    </div>
                </div>
            </div>
            <div className="custom_container mb-10">
                <ContactForm />
            </div>
        </>
    )
}
